import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import PrimarySplash from "../components/home/PrimarySplash";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import LightSplash from "../components/home/LightSplash";
import LightestSplash from "../components/home/LightestSplash";

export const playStoreURL =
  "https://play.google.com/store/apps/details?id=com.avicennamanagemymeds";
export const appStoreURL =
  "https://apps.apple.com/gb/app/managemymeds/id1504750252";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      googlePlayImage: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          fixed(width: 121) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appleScreenshot1Image: file(relativePath: { eq: "apple-screen-1.png" }) {
        childImageSharp {
          fixed(width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appleScreenshot2Image: file(relativePath: { eq: "apple-screen-2.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appleScreenshot3Image: file(relativePath: { eq: "apple-screen-3.png" }) {
        childImageSharp {
          fixed(width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      {/* <SEO
        title="Home"
        meta={[
          {
            name: "apple-itunes-app",
            content: "app-id=1504750252",
          },
        ]}
      />
      <Layout>
        <PrimarySplash data={data} />
        <div id="LightSplash" />
        <Footer linkId="#LightSplash" isNotBottomFooter />
        <LightSplash data={data} />
        <div id="LightestSplash" />
        <Footer linkId="#LightestSplash" isNotBottomFooter />
        <LightestSplash data={data} />
      </Layout> */}
    </>
  );
};

export default IndexPage;
